import { PopupButton } from 'react-calendly';

export default function CallButton(props) {

    return (
            <PopupButton
        url="https://calendly.com/inventuminternational/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text={props.text ?? "Book a call!"}
        utm={{salesforce_uuid: localStorage.getItem("gclid") || null}}
        
        className={props.textUrl ? `${props.textSize ?? 'text-2xl'} ${props.className}` :`text-ll ${props.textSize ?? 'text-2xl'} ${props.className} hover:scale-[1.05] transition-all ease-in-out bg-pg w-max rounded-xl items-center py-2 px-2` } 
      />
    )
}